import {MutatingDots} from "react-loader-spinner";

interface PropTypes {
    className?: string;
}
const Loader = (props: PropTypes) => {
    return (
        <div className={`absolute w-full h-full bg-white bg-opacity-50 ${props.className}`}>
            <div className="flex justify-center items-center h-full">
                <MutatingDots
                    height="100"
                    width="100"
                    color="#EB5765"
                    secondaryColor= '#EB5765'
                    radius='10.5'
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        </div>
    )
}

export default Loader;
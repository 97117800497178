import React, {lazy, Suspense} from 'react';
import {Routes, Route} from "react-router-dom";
import {AuthContextProvider} from "./contexts/AuthContext/AuthContext";
import Loader from "./components/moleculs/Loader/Loader";
import {AlertContextProvider} from "./contexts/AlertContext/AlertContext";


const DashboardRoute = lazy(() => import("./routes/DashboardRoute/DashboardRoute"));
const Login = lazy(() => import("./pages/auth/Login/Login"));
const ProtectRoute = lazy(() => import("./routes/ProtectRoute/ProtectRoute"));

function App() {
  return (
      <AuthContextProvider>
          <AlertContextProvider>
              <Routes>
                  <Route path="/*" element={
                      <Suspense fallback={<Loader className="w-screen h-screen"/>}>
                          <ProtectRoute/>
                      </Suspense>
                  }>
                      <Route path="*" element={
                          <Suspense fallback={<Loader className="w-screen h-screen"/>}>
                              <DashboardRoute/>
                          </Suspense>
                      }/>
                  </Route>
                  <Route path="/login" element={
                      <Suspense fallback={<Loader className="w-screen h-screen"/>}>
                          <Login/>
                      </Suspense>
                  }/>
              </Routes>
          </AlertContextProvider>
      </AuthContextProvider>
  );
}

export default App;

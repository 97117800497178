import {createContext, ReactEventHandler, ReactNode, useState} from "react";


interface PropTypes {
    children: ReactNode;
}
interface IAlertWithBottom {
    show: boolean;
    message: string | ReactNode;
    callback?: ReactEventHandler;
}

export const AlertContext = createContext<{
    alertWithBottom: IAlertWithBottom;
    setAlertBottom: (data: IAlertWithBottom) => void;
} | null>(null);

export const AlertContextProvider = (props: PropTypes) => {
    const [alertWithBottom, setAlertWithBottom] = useState<IAlertWithBottom>({
        show: false, message: "", callback: () => {}
    });

    const setAlertBottom = (data: IAlertWithBottom) => setAlertWithBottom({...data});

    return(
        <AlertContext.Provider value={{alertWithBottom, setAlertBottom}}>
            {props.children}
        </AlertContext.Provider>
    )
}
import {createContext, ReactNode, useState} from "react";
import {IUser} from "../../services/UserService/User";
import {Cookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
const cookies = new Cookies();

interface PropTypes {
    children: ReactNode;
}

export const AuthContext = createContext<{
    user: IUser;
    setData: (data: IUser) => void;

    logout: () => void;
} | null>(null);

export const AuthContextProvider = (props: PropTypes) => {
    const navigate = useNavigate();
    const [user, setUser] = useState<IUser>({
        username: "", id: "", alamat: "", no_hp: "", saldo: 0, accessToken: "", isLogin: false
    });

    const setData = (data: IUser) => {
        setUser(data);
    };

    const logout = () => {
        cookies.remove("component", {path: "/"});
        navigate("/login");
    }

    return(
        <AuthContext.Provider value={{user, setData, logout}}>
            {props.children}
        </AuthContext.Provider>
    )
}